
@import "fontvariant";
@import "inverse";

html { font-size:16px; }
.font-size-1 {
    .body_font_wariant(100%);
    
}
.font-size-2 {
    .body_font_wariant(200%);
}
.font-size-3 {
    .body_font_wariant(300%);
}
.font-size-4 {
    .body_font_wariant(600%);
}
