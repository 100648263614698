.body_font_wariant(@fontsize)
{
    font-size: @fontsize !important;
    
    & table  { font-size: 80% !important; } 
    & form   {  }
    & .navbar  {font-size: 100%  !important;}
    & button.btn { font-size: 80% !important; font-weight: bold; }
    & .form-control {font-size:  80% !important; height:auto;}
    
    
}
