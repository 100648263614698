@import "bootstrap/bootstrap";
@import "font-awesome/font-awesome";

@import "styleview";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
	font-weight: 301; 
        background: #CCCFD2;
}

.kontakt_form label {background: none;}

h1{ font-size:250% !important;}
h2{ font-size:200% !important;}
h3{ font-size:150% !important;}
h4{ font-size:110% !important;}
h5{ font-size:100% !important;}


.fa-butt 
{
    /*width: 50px; height: 50px;*/
    
    /*height:2em; width:2em;*/
     
     
    margin-left: 2px;
    margin-top: 10px;
    /*padding:8px;
    box-shadow: 0px 0px 2px gray;*/
    cursor: pointer;
    font-size: 120%;   
    
    
    &.f1{
    font-size: 100%;   
    /*padding:8px;*/
    }
    &.f2{
     font-size: 120%;   
    /*padding:5px;*/
    }
    &.f3{
     font-size: 150%;   
    /*padding:3px;*/
    }
}

.viewinverse {
    background: #000 !important; color: #ffff00 !important;
    
    & * { background: #000 !important; color: #ffff00 !important; }
    & a {text-decoration: underline !important;}
    & .table, & .table td { border:solid 4px #ffff00  !important;}
    & button.btn  { background:#ffff00  !important; color:#000  !important;  }
    & .panel {  border:solid 4px #ffff00  !important; border-radius: 0 !important;  }
    & .navbar {  border:solid 4px #ffff00  !important; border-radius: 0 !important; }
    & button.navbar-toggle  { background: #ffff00 !important; ;}
    & .form-control { border:solid 4px #ffff00  !important;}
    & .page_content .panel-heading   { border:0;}
}



/* =================================== */
#nav_side{padding:0;}
#nav_side ul ul { padding-left:15px ;padding-bottom: 10px;  /* border-left:2px dotted #666;*/;}

a:focus {
    background: rgba(0,0,0,0.2);
}

.breadcrumb {/* font-size: 70%; display: none;*/}
.bip_header
{
    background:#337AB7;
    border-bottom: solid 5px #A60D13;
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b5bdc8+0,828c95+36,28343b+100;Grey+Black+3D */
background: #b5bdc8; /* Old browsers */
background: -moz-linear-gradient(top, #b5bdc8 0%, #828c95 36%, #28343b 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, #b5bdc8 0%,#828c95 36%,#28343b 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, #b5bdc8 0%,#828c95 36%,#28343b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b5bdc8', endColorstr='#28343b',GradientType=0 ); /* IE6-9 */

background: url(/img/big_bg.jpg) no-repeat center/ 100% 150%;
    
    & #logo {width:100px;margin:20px auto; }
    & .nav_links 
    {
        text-align:center;
        & .fa { font-size: 90%; margin:5px; }
        & a{ color:#fff; font-size:80%; padding:5px; text-shadow: 1px 1px 0 #252E3F;}
        
    }
    & h2{ color:#fff; text-shadow:0 0 5px #000;}
    & h3{ color:#fff; text-shadow:0 0 5px #000;}    
}    

.bip_content{
    background: #fff; padding-top:15px;
}

#main-content { padding-top:15px;}
.page_content {  border:0; }
.page_content .panel-heading { border-bottom: solid 2px #eee; background: transparent; }


.bip_footer
{
    background:#556068;  padding-top:30px;
    /*border-top: solid 1px #333;*/
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b5bdc8+0,828c95+36,28343b+100;Grey+Black+3D */
background: #b5bdc8; /* Old browsers */
background: -moz-linear-gradient(top, #b5bdc8 0%, #828c95 36%, #CCCFD2 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, #b5bdc8 0%,#828c95 36%,#CCCFD2 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, #b5bdc8 0%,#828c95 36%,#CCCFD2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b5bdc8', endColorstr='#CCCFD2',GradientType=0 ); /* IE6-9 */

.panel  {background: rgba(255,255,255,0.7); }
}